/*
_reset.scss
*/

/* apply a natural box layout model to all elements, but allowing components to change */
html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html,
body {
	margin: 0;
	padding: 0;
	// color: black;
	font-size: 16px;
}

button {
	cursor: pointer;
}
