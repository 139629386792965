/*
FileUpload.scss
*/
@import "../../../styles/_colours.module.scss";

#formFileUpload {
	height: 200px;
	width: 100%;
	max-width: 100%;
	text-align: center;
	position: relative;
}

#inputFileUpload {
	display: none;
}

#labelFileUpload {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	border-color: #cbd5e1;
	background-color: transparentize($login-blue, 0.46);
	&.drag-active {
		background-color: #ffffff;
	}
}

.stardrop {
	button {
		&.upload-button {
			cursor: pointer;
			padding: 0.25rem 12px 0.25rem 34px;
			font-size: 1rem;
			border: none;
			background-color: transparent;
			position: relative;
			&::after {
				mask-repeat: no-repeat;
				mask-image: url("../../../images/upload.svg");
				mask-position: left center;
				mask-size: 24px;
				background: rgb(56, 84, 171);
				background: radial-gradient(circle, rgba(56, 84, 171, 1) 0%, rgba(30, 50, 92, 1) 100%);
			}
			&::after,
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 28px;
			}
			&::before {
				background-color: white;
				top: 38%;
				left: 6px;
				height: 10px;
				width: 12px;
			}
			color: $foreground-colour;
			&::hover {
				text-decoration-line: underline;
			}
		}
	}
}

#dragFileElement {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
