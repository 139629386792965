/*
_skin.module.scss
*/
// @import "_colours.module.scss";

@mixin affected_by_skin($mask-background, $background-colour, $panel-colour, $foreground-colour, $article-base-background, $active-colour, $datasource-colour, $group-colour, $home-colour, $policy-colour, $user-colour) {
	$dialog-colour: $home-colour;
	.dialog {
		&-box {
			border-color: $dialog-colour;
			background: white;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
			header {
				height: 29px;
				background: lighten($dialog-colour, 20);
				button {
					background-color: lighten($dialog-colour, 10);
					border-radius: 0;
					background-image: url("../images/cross.svg");

					height: 29px;
					width: 29px;
					max-width: 29px;
					max-height: 29px;
					background-size: 60%;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
			footer {
				button {
					&#dialogButtonYes,
					&#dialogButtonOk {
						background-color: $common-orange;
					}
					&#dialogButtonNo {
						background-color: $dialog-colour;
					}
				}
				ul {
					li {
						a {
							color: $foreground-colour;
						}
					}
				}
			}
		}
		.background {
			background: $mask-background;
		}
	}
	#login {
		background: linear-gradient(-45deg, #f9d184 0%, #cbd326 50%, #b3ddef 100%);
		background-repeat: no-repeat;

		color: $foreground-colour;
		#passwordSet,
		#signinForm {
			background-color: $panel-colour;
		}
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-image: url("../images/outline_stardrop.svg");
			background-repeat: no-repeat;
			background-size: 100vmin;
			background-position: calc(100% + 30vmin) center;
			opacity: 0.4;
		}
	}
	#adminPanel,
	#login {
		footer {
			background-color: $panel-colour;
			ul {
				li {
					a {
						color: $foreground-colour;
					}
				}
			}
		}
	}
	#spinner {
		background-color: $mask-background;
	}
}

.stardrop {
	$active-colour: cyan;
	$article-base-background: red;
	$background-colour: $background-colour-stardrop;
	$datasource-colour: red;
	$foreground-colour: $foreground-colour-stardrop;
	$group-colour: red;
	$home-colour: #979797;
	$mask-background: rgba(153, 153, 153, 0.5);
	$panel-colour: $panel-colour-stardrop;
	$policy-colour: red;
	$user-colour: red;
	@include affected_by_skin($mask-background, $background-colour, $panel-colour, $foreground-colour, $article-base-background, $active-colour, $datasource-colour, $group-colour, $home-colour, $policy-colour, $user-colour);
	#login {
		&:before {
			background-image: url("../images/outline_stardrop.svg");
		}
	}
	&.file-manager {
		#login {
			background: #f9f8f7;
			&:before {
				background-image: url("../images/outline_stardrop_gradient.svg");
			}
		}
	}
}

.semeta {
	$active-colour: cyan;
	$article-base-background: red;
	$background-colour: $background-colour-semeta;
	$datasource-colour: red;
	$foreground-colour: $foreground-colour-semeta;
	$group-colour: red;
	$home-colour: #949494;
	$mask-background: rgba(153, 153, 153, 0.5);
	$panel-colour: $panel-colour-semeta;
	$policy-colour: red;
	$user-colour: red;
	@include affected_by_skin($mask-background, $background-colour, $panel-colour, $foreground-colour, $article-base-background, $active-colour, $datasource-colour, $group-colour, $home-colour, $policy-colour, $user-colour);
	#login {
		&:before {
			background-image: url("../images/outline_semeta.svg");
			background-size: 80vmin;
		}
	}
}
