/*
_mixins.module.scss
*/

@mixin text-contrast($n) {
	$color-brightness: round((red($n) * 299) + (green($n) * 587) + (blue($n) * 114) / 1000);
	$light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);

	@if abs($color-brightness) < ($light-color/2) {
		color: white;
	} @else {
		color: black;
	}
}

@mixin threeSixtyRotate {
	@keyframes threeSixtyRotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

@mixin active($active-colour: red) {
	background-color: $active-colour;
	color: black;
}

@function toRem($value) {
	$remValue: $value / 16px;
	@return #{$remValue}rem;
}

@mixin button-common() {
	padding: 10px 20px;
	font-weight: bold;
	border: none;
	border-radius: $button-border-radius;
	height: $button-height;
	outline: none;
}

@mixin dialog-background {
	margin: 0;
	position: absolute;
	width: 100%;
	height: 100%;
}

@mixin disabled {
	cursor: not-allowed;
	background-color: lightgrey;
	color: grey;
}

@mixin button {
	padding: 10px 20px;
	font-weight: bold;
	border: none;
	border-radius: $button-border-radius;
	height: $button-height;
	outline: none;
	&.disabled {
		@include disabled();
	}
}
