/*
_colours.module.scss
*/

$background-colour-stardrop: #1b293f;
$background-colour-semeta: white;
$panel-colour-stardrop: rgba(255, 255, 255, 0.8);
$panel-colour-semeta: rgba(255, 255, 255, 0.8);
$foreground-colour: #181716;
$foreground-colour-stardrop: $foreground-colour;
$foreground-colour-semeta: $foreground-colour;

$link-colour: #63a72d;
$common-orange: #fccd00;

$login-blue: #1e325c;
