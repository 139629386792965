/*
CookieBanner.scss
*/
@import "../../styles/_colours.module.scss";
@import "../../styles/_reset.module.scss";
@import "../../styles/_layout.module.scss";
@import "../../styles/_media-queries.module.scss";
@import "../../styles/_mixins.module.scss";
@import "../../styles/_variables.module.scss";

$button-height: 46px;
$button-font-size: 1.3rem;
#CookieBanner {
	position: fixed;
	padding: 0 20px;
	background: white;
	color: black;
	bottom: 20px;
	max-width: 1500px;
	border-radius: 6px;
	display: none;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	z-index: 12;
	p {
		a {
			color: $link-colour;
		}
	}
	.button-group {
		width: 100%;
		max-width: 600px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		button {
			width: 46%;
			height: $button-height;
			border-radius: $button-height/2;
			background: white;
			border: 3px solid $common-orange;
			color: $common-orange;
			font-size: $button-font-size;
			line-height: $button-font-size;
			&:first-of-type {
				color: white;
				background-color: $common-orange;
			}
		}
	}
}
.debug {
	#CookieBanner {
		// background: rgba(247, 186, 186, 0.3);
		.button-group {
			background: rgba(0, 0, 255, 0.205);
		}
	}
}

@include respond-above(m) {
	#CookieBanner {
		max-height: 200px;
	}
}

@include respond-above(l) {
	#CookieBanner {
		max-height: 114px;
		justify-content: space-between;
		p {
			max-width: 56%;
		}
		.button-group {
			width: 39%;
			margin-right: 2%;
		}
	}
}
