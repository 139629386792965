/*
_variables.module.scss
*/

$button-border-radius: 4px;
$button-height: 36px;
$button-space: 2px;
$colour-transition-time: 2s;
$common-border-radius: 16px;

$common-grid-gap: 16px;
$transition-time: 1s;

$footer-margin: 1.2vmin;
$footer-height: 4.5vmin;
