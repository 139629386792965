/*
FileManager.scss
*/
@import "../../styles/_colours.module.scss";
@import "../../styles/_reset.module.scss";
@import "../../styles/_layout.module.scss";
@import "../../styles/_media-queries.module.scss";
@import "../../styles/_mixins.module.scss";
@import "../../styles/_variables.module.scss";

$header-height: 150px;
$selected-colour: rgba(23, 131, 135, 0.5);
#adminPanel {
	width: 100%;
	font-size: 16px;
	font-family: proxima-nova, Arial, Helvetica, sans-serif;
	.to-be-scrollable {
		overflow: auto;
		margin: $header-height 0;
	}
	footer {
		position: fixed;
	}
	header {
		display: grid;
		grid-template-columns: 1fr 3fr 4fr 2fr;
		grid-template-rows: 1fr 1fr;
		grid-template-areas:
			"logo h1 spacer logout"
			"description description description description";
		background-color: $login-blue;
		color: white;
		height: $header-height;
		position: fixed;
		width: 100%;
		z-index: 10;
		h1 {
			font-weight: normal;
			grid-area: h1;
			align-self: center;
		}
		p {
			grid-area: description;
			margin: 6px 48px;
		}
		img {
			width: 275px;
			margin: 16px 32px;
			align-self: center;
			grid-area: logo;
		}
		#path {
			padding: 8px;
			display: none;
		}
		.logout {
			align-self: center;
			grid-area: logout;
			padding: 0 7px 0 32px;
			font-weight: bold;
			margin: 2px 32px;
			width: 140px;
			justify-self: right;
			background-color: transparent;
			position: relative;
			border: 2px solid white;
			border-radius: 4px;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 12px;
				height: 100%;
				width: 28px;
				mask-repeat: no-repeat;
				mask-image: url("../../images/power-off.svg");
				mask-position: left center;
				mask-size: 24px;
				background-color: white;
			}
			color: white;
		}
	}
	h1 {
		&.loading {
			height: 100vh;
			width: 100vw;
			text-align: center;
			margin: 0;
			line-height: 100vh;
		}
	}
}
span {
	&.debug {
		display: none;
	}
}
.debug {
	span {
		&.debug {
			display: contents;
		}
	}
}
#split,
#fileSplit {
	display: flex;
	.gutter {
		cursor: col-resize;
	}
}
#fileSplit {
	.gutter {
		background: linear-gradient(to right, white 0px, lightgray 3px, white 3.1px, white 5px);
	}
}
#hierarchy,
#files {
	border: 2px inset;
	overflow: hidden;
	position: relative;
}
#files {
	overflow-y: visible;
	ul {
		margin: 24px 0;
		padding: 0 12px;
		li {
			font-size: 0.9rem;
			height: 30px;
			text-overflow: ellipsis;
			display: block;
			white-space: nowrap;
			overflow: hidden;
			div,
			p {
				text-overflow: ellipsis;
				display: block;
				white-space: nowrap;
				overflow: hidden;
			}
		}
	}
}
button {
	&.delete,
	&.details,
	&.icons,
	&.new-folder {
		width: 30px;
		height: 30px;
		text-indent: -9999px;
		border-color: lightgray;
		border-width: 2px;
		border-radius: 4px;
		margin: 4px;
		padding: 0;
		outline: none;
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-color: white;
		border-style: outset;
		&.selected {
			border-style: inset;
		}
	}
	&.delete {
		&::after {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			mask-image: url("../../images/trash.svg");
			background-color: gray;
			// background-position: 18% 18%;
			mask-position: center center;
			mask-size: 64%;
			mask-repeat: no-repeat;
		}
		// border: 1px solid red;
		position: absolute;
		right: 0px;
	}
	&.details {
		background-image: url("../../images/details.svg");
	}
	&.icons {
		background-image: url("../../images/icons.svg");
	}
	&.new-folder {
		background-image: url("../../images/folder_new.svg");
		background-size: 90%;
	}
}
.prevent-select {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
.folder {
	position: relative;
	background-image: url("../../images/folder_closed.svg");
	background-size: 30px;
	background-repeat: no-repeat;
	min-height: 26px;
	cursor: pointer;
	display: block;
	font-size: 0.9rem;
	overflow-y: hidden;
}
#hierarchy {
	padding: 48px;
	.folder {
		position: relative;
		&.selected {
			&::before {
				content: "";
				position: absolute;
				height: 30px;
				width: 100%;
				background-color: $selected-colour;
				z-index: -1;
			}
		}
		margin-left: 0px;
		.folder {
			margin-left: 30px;
		}
		&.hidden {
			> p {
				display: none;
			}
			background-image: none;
			margin-left: 0;
		}
		&:not(.hidden) {
			background-image: url("../../images/folder_back.svg");
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				background-image: url("../../images/folder_front.svg");
				background-size: 30px;
				background-repeat: no-repeat;
				background-position: top left;
				height: 26px;
				width: 30px;
				z-index: 2;
				transform-origin: 0 26px;
				transition: transform 0.5s;
			}
			p {
				margin: 8px 0 0 46px;
				min-height: 18px;
			}
		}
		&.expanded {
			&::after {
				transform: skew(-15deg) scaleY(0.9);
				transition: transform 0.5s;
			}
		}
	}
}

#files {
	li {
		list-style: none;
		text-indent: 0;
		min-height: 30px;
	}
	.folder {
		background-position: center left;
	}
	.data,
	.file,
	.folder {
		&.selected {
			background-color: $selected-colour;
		}
	}
	.folder,
	.file,
	.data {
		min-height: 30px;
		p {
			// padding-left: 36px;
			margin: 8px 0 0 46px;
		}
		display: block;
		font-size: 0.9rem;
		overflow-y: hidden;
	}
	&.icons {
		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 120px;
				height: 120px;
				min-width: 120px;
				min-height: 120px;
				.folder,
				.file {
					// border: 1px solid red;
					min-height: 120px;
					display: flex;
					background-position: top center;
					background-size: 60%;
					p {
						margin: 60% 0 0 0;
						width: 100%;
						text-align: center;
					}
				}
			}
		}
	}
	.data {
		p {
			margin: 8px 0 0 0;
		}
	}
	.file {
		// margin-left: 12px;
		//padding-left: -8px;
		position: relative;
		background-image: url("../../images/file.svg");
		&.stardrop {
			background-image: url("../../images/stardropfile.svg");
		}
		background-size: 30px;
		background-repeat: no-repeat;
		background-position: top left;
		// background-color: white;
		// border: 1px solid black;
		// box-sizing: border-box;
		cursor: pointer;
	}
}
