/*
Login.scss
*/
@import "../../styles/_colours.module.scss";
@import "../../styles/_reset.module.scss";
@import "../../styles/_layout.module.scss";
@import "../../styles/_media-queries.module.scss";
@import "../../styles/_mixins.module.scss";
@import "../../styles/_variables.module.scss";

@keyframes threeSixtyRotate {
	0% {
		transform: translatez(-10em) rotatey(-30deg) rotatez(0deg);
	}
	100% {
		transform: translatez(-10em) rotatey(-30deg) rotatez(360deg);
	}
}
#scrollIfNeedTo {
	border: 1px inset;
	overflow-y: scroll;
	max-height: 16vh;
	padding: 0;
	margin: 23px 0 0 0;
}
#login {
	min-height: 100svh;
	font-size: 16px;
	font-family: proxima-nova, Arial, Helvetica, sans-serif;
	background-repeat: no-repeat;
	overflow: hidden;
	width: 100%;
	display: flex;
	justify-content: center;
	$max-width: 1660px;
	#container {
		height: 100%;
		width: 100%;
		max-width: $max-width;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		padding-bottom: $footer-height * 2;
	}

	$common-border-radius: 16px;
	$common-indent: 3.2%;
	$common-font-size: 100%;
	$input-font-size: 2.3vmin;

	::placeholder {
		color: $foreground-colour;
		opacity: 1; /* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: $foreground-colour;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: $foreground-colour;
	}

	#optibrium {
		display: none;
		position: absolute;
		top: 2.4vmin;
		left: 10px;
		width: 120px;
		z-index: 2;
		img {
			width: 100%;
		}
	}

	#welcomeStardrop,
	#welcomeSemeta {
		margin: 0 0 6px 0;
		position: relative;
		span {
			position: absolute;
			text-align: left;
			width: 100%;
			left: 30%; //Calc(0.11 * $width);
			top: -1rem;
			font-size: 1.4rem;
		}
	}
	#welcomeStardrop {
		background-image: url("../../images/stardrop_v2.svg");
		width: 100%;
		background-size: 100%;
		background-repeat: no-repeat;
		height: 80px;
	}

	#welcomeSemeta {
		background-image: url("../../images/semeta_v2.svg");
		width: 100%;
		background-size: 100%;
		background-repeat: no-repeat;
		height: 80px;
		span {
			left: 33%;
		}
	}

	#passwordSet,
	#signinForm {
		width: 100%;
		background: rgba(255, 255, 255, 0.85);
		position: relative;
		padding: 5vmin;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		border-radius: 16px;
		.narrow {
			max-width: 346px;
			width: 90%;
			margin: 0 auto 0;
		}
		.email-readonly {
			font-size: 1.6rem;
			padding-left: 14px;
		}
		input {
			height: 1rem;
			&.password {
				&[type="password"],
				&[type="text"] {
					padding-right: 50px;
					&::-ms-reveal {
						display: none;
					}
				}
			}
			&[type="password"],
			&[type="text"],
			&[type="email"] {
				margin: 1vmin 0 0;
				background-color: white;
				border: 2px solid $foreground-colour;
				transition: border-color 0.5s;
				padding: 0 13px;
				color: $foreground-colour;

				font-size: 100%;
				height: 5vmin;
				&:-webkit-autofill::first-line,
				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus,
				&:-webkit-autofill:active {
					outline: none;
					color: $foreground-colour;
				}
				&.error {
					border-color: $common-orange;
					transition: border-color 0.5s linear;
				}
				border-radius: $common-border-radius;
			}
			&[type="checkbox"] {
				margin: 0;
				vertical-align: -3px;
			}
			&[type="password"],
			&[type="text"],
			&[type="email"] {
				height: 42px; //5.5vmin;
				border-radius: 12px;
				width: 100%;
			}
			&[type="text"],
			&[type="password"] {
				margin: 2px 0 0 0;
			}
			&[type="checkbox"] {
				margin: 13px 3px 0 0;
			}
		}
		span {
			&.reveal-password {
				position: absolute;
				z-index: 0;
				background-image: url("../../images/reveal_password.svg");
				background-repeat: no-repeat;
				background-size: 38px;
				background-position: center center;
				background-color: transparent;
				text-indent: -99999px;
				top: 50%;
				margin: -21px 0 0 0;
				right: 10px;
				height: 42px;
				width: 42px;
				cursor: pointer;
				&.hide {
					display: none;
				}
				&.reveal {
					&::after {
						content: "";
						position: absolute;
						width: 40px;
						height: 0px;
						border-top: 2px solid white;
						border-bottom: 2px solid black;
						top: 14px;
						left: -12px;
						transform-origin: 20px 20px;
						transform: rotateZ(45deg);
					}
				}
			}
		}

		button {
			border-radius: $common-border-radius;
			background-color: $common-orange;
			border: none;
			font-size: 100%;
			font-weight: normal;
			line-height: 100%;
			// color: white;
			height: 12.5%;
			width: 100%;
			margin: 4% 0 0 0;
			&.disabled {
				@include disabled();
			}
		}
		a {
			text-align: left;
			font-size: 15px;
			color: $foreground-colour;
			&.licence-link {
				color: #63a72d;
				font-weight: bold;
				&-one {
					text-decoration: none;
				}
			}
			&.forgot-password {
				margin: 10px 0 0 14px;
				display: block;
				text-decoration: none;
			}
		}
		button,
		span {
			&.forgot-password {
				cursor: pointer;
				margin: 10px 0 0 14px;
				display: block;
				text-decoration: none;
				border: none;
				background: none;
			}
		}

		div {
			&.error,
			&.email-error,
			&.password-error {
				font-size: $common-font-size;
				color: $common-orange;
				margin-left: $common-indent;
				height: 21px;
				max-height: 0;
				transition: max-height 0.5s linear;
				&.show {
					transition: max-height 0.5s linear;
					max-height: 26px;
				}
			}
			&.email-error,
			&.password-error {
				height: 50px;
				&.show {
					transition: max-height 0.5s linear;
					max-height: 50px;
				}
			}
		}
	}

	#eulaText {
		margin: 0 auto;
		p {
			margin: 8px 0;
			line-height: 1.3rem;
			&.eula {
				margin: 0;
			}
		}

		h2 {
			font-size: 16px;
			margin: 0; //23px 0 0 0;
		}
	}
}

@include respond-above(s) {
	@media screen and (orientation: portrait) {
		#login {
			#optibrium {
				display: block;
			}
			#passwordSet,
			#signinForm {
				width: 75vmin;
			}
		}
	}
	@media screen and (orientation: landscape) {
		#login {
			#optibrium {
				display: block;
				width: 120px;
				position: absolute;
				top: 2.4vmin;
				left: 0;
				margin: 0;
			}
			#container {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			#welcomeStardrop,
			#welcomeSemeta {
				display: block;
			}
			#passwordSet,
			#signinForm {
				width: 120vmin;
			}
		}
		#starDropRings {
			.starDropRing {
				left: -15%;
				&:first-of-type {
					left: -13%;
				}
				&:last-of-type {
					left: -17%;
				}
			}
		}
	}
}
@include respond-above(m) {
	#login {
		#container {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		#optibrium {
			width: 140px;
			position: absolute;
			top: 2.4vmin;
			left: 0;
			margin: 0;
		}
		#welcomeStardrop,
		#welcomeSemeta {
			margin: 0 0 4px 0;
		}
		#welcomeStardrop {
			width: 100%;
			background-size: 100%;
			background-repeat: no-repeat;
			height: 80px;
		}
		#welcomeSemeta {
			width: 100%;
			background-size: 100%;
			background-repeat: no-repeat;
			height: 80px;
		}

		#passwordSet,
		#signinForm {
			width: 600px;
			padding: 20px;
			.narrow {
				margin: 0 auto 0;
			}
			input {
				&[type="password"],
				&[type="text"],
				&[type="email"] {
					margin: 4px 0 0 0;
					font-size: 24px;
					height: 63px;
					border-radius: 12px;
					width: 100%;
				}
				&[type="text"],
				&[type="password"] {
					margin: 2px 0 0 0;
				}
				&[type="checkbox"] {
					margin: 13px 3px 0 0;
				}
			}
			label {
				margin: 18px 0 0 0;
				display: block;
			}
			button {
				font-size: 170%;
				line-height: 152%;
				height: 60px;
				border-radius: 12px;
				margin: 12px 0 0 0;
			}
		}
	}

	@media screen and (orientation: landscape) {
		#login {
			#optibrium {
				width: 140px;
			}
		}
		#starDropRings {
			.starDropRing {
				left: 0;
				&:first-of-type {
					left: -3%;
				}
				&:last-of-type {
					left: -7%;
				}
			}
		}
	}
}
@include respond-above(l) {
	#login {
		#optibrium {
			width: 160px;
		}

		#container {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		#passwordSet,
		#signinForm {
			width: 760px;
			margin-top: 20px;
		}
		#welcomeSemeta {
			height: 100px;
		}
	}
	@media screen and (orientation: landscape) {
		#starDropRings {
			.starDropRing {
				left: -25%;
				&:first-of-type {
					left: -23%;
				}
				&:last-of-type {
					left: -27%;
				}
			}
		}
	}
}
@include respond-above(xl) {
	#login {
		#passwordSet,
		#signinForm {
			width: 760px;
			margin-top: 20px;
			padding: 40px;
		}
		#eulaText {
			margin-top: 20px;
		}
	}
}

@include respond-above(xxl) {
	.semeta {
		#login {
			background-position: 0 0;
		}
	}
	#login {
		#container {
			padding-bottom: 6.3vmin;
		}
		#optibrium {
			width: 240px;
			top: 4.9vmin;
		}

		#passwordSet,
		#signinForm {
			width: 960px;
			height: 79svh;
			max-height: 760px;
			min-height: 670px;
			margin-top: 13svh;
			padding: 3.5svh 56px;
			.narrow {
				margin: 44px auto 0;
			}
		}

		#scrollIfNeedTo {
			height: 11vh;
			max-height: 140px;
		}
		#eulaText {
			width: 820px;
		}
		#welcomeStardrop,
		#welcomeSemeta {
			margin: 0 0 24px 0;
		}
		#welcomeStardrop {
			width: 100%;
			background-size: 100%;
		}
		#welcomeSemeta {
			width: 100%;
			background-size: 100%;
			max-height: 98px;
		}
	}
}
