/*
media-queries.scss
*/

/* Do not edit media-queries.css
edit media-queries.scss in the styles directory */

// A map of breakpoints.
$breakpoints: (
	s: 580px,
	m: 760px,
	l: 960px,
	xl: 1024px,
	xxl: 1260px,
	xxxl: 1400px,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(s) {}
@mixin respond-above($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		// Write the media query.
		@media (min-width: $breakpoint-value) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(s) {}
@mixin respond-below($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		// Write the media query.
		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(s, m) {}
@mixin respond-between($lower, $upper) {
	// If both the lower and upper breakpoints exist in the map.
	@if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
		// Get the lower and upper breakpoints.
		$lower-breakpoint: map-get($breakpoints, $lower);
		$upper-breakpoint: map-get($breakpoints, $upper);

		// Write the media query.
		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
			@content;
		}

		// If one or both of the breakpoints don't exist.
	} @else {
		// If lower breakpoint is invalid.
		@if (map-has-key($breakpoints, $lower) == false) {
			// Log a warning.
			@warn 'Your lower breakpoint was invalid: #{$lower}.';
		}

		// If upper breakpoint is invalid.
		@if (map-has-key($breakpoints, $upper) == false) {
			// Log a warning.
			@warn 'Your upper breakpoint was invalid: #{$upper}.';
		}
	}
}

html.debug body::before {
	content: "XS";
	background: rgba(200, 0, 0, 0.5);
	color: white;
	width: 80px;
	height: 26px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	text-align: center;
	border-radius: 0 0 12px 0;
}
@include respond-above(s) {
	html.debug body::before {
		content: "S (P)";
		@media screen and (orientation: landscape) {
			content: "S (L)";
		}
	}
}
@include respond-above(m) {
	html.debug body::before {
		content: "M (P)";
		@media screen and (orientation: landscape) {
			content: "M (L)";
		}
	}
}
@include respond-above(l) {
	html.debug body::before {
		content: "L (P)";
		@media screen and (orientation: landscape) {
			content: "L (L)";
		}
	}
}
@include respond-above(xl) {
	html.debug body::before {
		content: "XL (P)";
		@media screen and (orientation: landscape) {
			content: "XL (L)";
		}
	}
}
@include respond-above(xxl) {
	html.debug body::before {
		content: "XXL (P)";
		@media screen and (orientation: landscape) {
			content: "XXL (L)";
		}
	}
}
