/*
Dialog.scss
*/
@import "../../styles/_colours.module.scss";
@import "../../styles/_variables.module.scss";
@import "../../styles/_mixins.module.scss";

$dialog-width: 600px;

.dialog {
	border: none;
	width: 100%;
	height: 100%;
	position: fixed;
	cursor: not-allowed;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	background: transparent;
	div.background {
		@include dialog-background;
	}

	&-box {
		z-index: 1000;
		width: $dialog-width;
		max-width: calc(100vw - 10px);
		position: absolute;
		border-width: 4px;
		border-style: solid;

		div {
			padding: 16px;
		}
		header {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			button {
				padding: 0;
				display: block;
				border-width: 1px;
			}
		}
		footer {
			display: flex;
			justify-content: flex-end;
			margin: 1vh;
			button {
				border-radius: $common-border-radius;
			}
		}
	}
}
