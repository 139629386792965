/*
App.scss
*/
@import "./styles/_reset.module.scss";
@import "./styles/colours.module.scss";
@import "./styles/_layout.module.scss";
@import "./styles/_media-queries.module.scss";
@import "./styles/_mixins.module.scss";
@import "./styles/_variables.module.scss";
@import "./styles/_skin.module.scss";

html,
body {
	// height: 100%;
	width: 100%;
	// background-color: $background-colour;
	// &.debug {
	// 	background-image: url("images/phone.jpg");
	// 	background-repeat: no-repeat;
	// 	background-size: cover;
	// 	background-position: center; //12px 46px; // 16px -13px; //center;
	// }
	// color: $foreground-colour; // #3f3f3f;
	font-size: 16px;
	font-family: arial; // proxima-nova, Arial, Helvetica, sans-serif;
	// }
	// #fakeback {
	// 	height: 100%;
	// 	width: 100%;
	// background-image: url("images/stardrop_logo.svg");
	// background-repeat: no-repeat;
	// background-size: 70vh; //84%;
	// background-position: center; //106% 51%;
	// background-position: 16px -13px; //center;
	// overflow: hidden;
	display: flex;
	justify-content: center;
}

.button-group {
	display: flex;
	justify-content: flex-end;
	padding: 4px 0;
	button {
		margin: 4px 0 4px 8px;
	}
}

@media screen and (orientation: landscape) {
	#starDropRings {
		.starDropRing {
			left: -40%;
			&:first-of-type {
				left: -53%;
			}
			&:last-of-type {
				left: -57%;
			}
		}
	}
}
.App {
	text-align: center;
}

$spinner-dimension: 200px;
$spinner-duration: 6s; //24s;
@keyframes spinner-rotate {
	0% {
		transform: rotateZ(0);
	}
	100% {
		transform: rotateZ(360deg);
	}
}

#spinner {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(153, 153, 153, 0.5);
	cursor: not-allowed;
	z-index: 900;
	&::after {
		content: "";
		position: absolute;
		// background-image: url("./images/spinner.svg");
		background-repeat: no-repeat;
		background-size: $spinner-dimension;
		background-position: center center;
		border-radius: 50%;
		border-color: #e2e2e2 #333333 #e2e2e2 #e2e2e2;
		border-width: 20px;
		border-style: solid;
		height: $spinner-dimension;
		width: $spinner-dimension;
		left: 50%;
		top: 50%;
		margin: Calc(-$spinner-dimension/2) 0 0 Calc(-$spinner-dimension/2);
		animation-name: spinner-rotate;
		animation-duration: $spinner-duration;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
	.ringone,
	.ringtwo,
	.ringthree {
		position: absolute;
		// background-image: url("./images/stardrop_logo_approximate.svg");

		background-repeat: no-repeat;
		background-size: contain;
		height: $spinner-dimension;
		width: $spinner-dimension;

		left: 50%;
		top: 50%;
		margin: Calc(-$spinner-dimension/2) 0 0 Calc(-$spinner-dimension/2);
		display: block;
		border-radius: 50%;
		animation-name: spinner-rotate;
		animation-duration: $spinner-duration;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		opacity: 0.8;
	}
	.ringone {
		animation-delay: 0s;
	}
	.ringtwo {
		animation-delay: -$spinner-duration/3;
	}
	.ringthree {
		animation-delay: -2 * $spinner-duration/3;
	}
}

#root {
	width: 100%;
	display: flex;
	justify-content: center;
}

$common-indent: 3.2%; // 1vmin;
$common-font-size: 100%; //1.5vmin;
$common-orange: #ee730c;
$input-font-size: 2.3vmin;

::placeholder {
	color: $foreground-colour;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: $foreground-colour;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: $foreground-colour;
}

.app {
	width: 100%;
	display: flex;
	justify-content: center;
	button {
		@include button;
	}
}
@include respond-above(s) {
	@media screen and (orientation: landscape) {
		body {
			background-size: 170vh; //84%;
		}
	}
	#starDropRings {
		.starDropRing {
			left: -15%;
			&:first-of-type {
				left: -13%;
			}
			&:last-of-type {
				left: -17%;
			}
		}
	}
}
@include respond-above(m) {
	body {
		background-size: 100vh; //84%;
	}
	@media screen and (orientation: landscape) {
		#starDropRings {
			.starDropRing {
				left: 0;
				&:first-of-type {
					left: -3%;
				}
				&:last-of-type {
					left: -7%;
				}
			}
		}
	}
}
@include respond-above(l) {
	body {
		background-size: 124vh; //84%;
	}
	@media screen and (orientation: landscape) {
		#starDropRings {
			.starDropRing {
				left: -25%;
				&:first-of-type {
					left: -23%;
				}
				&:last-of-type {
					left: -27%;
				}
			}
		}
	}
}
