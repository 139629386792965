/*
Footer.scss
*/
@import "../../styles/_colours.module.scss";
@import "../../styles/_reset.module.scss";
@import "../../styles/_layout.module.scss";
@import "../../styles/_media-queries.module.scss";
@import "../../styles/_mixins.module.scss";
@import "../../styles/_variables.module.scss";

#adminPanel,
#login {
	footer {
		position: absolute;
		margin: $footer-margin 0;
		background-color: rgba(255, 255, 255, 0.85);
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		z-index: 10;
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			height: $footer-height;
			max-height: $footer-height;
			line-height: $footer-height;
			li {
				text-indent: 0;
				display: inline-block;
				padding: 0 2px;
				a {
					font-size: 1rem;
					text-decoration: none;
				}
			}
		}
	}
}
#adminPanel {
	footer {
		position: fixed;
	}
}

@include respond-above(s) {
	#adminPanel,
	#login {
		footer {
			ul {
				li {
					padding: 0 6px;
					a {
						font-size: 1.2rem;
					}
				}
			}
		}
	}
}
@include respond-above(l) {
	#adminPanel,
	#login {
		footer {
			ul {
				li {
					padding: 0 24px;
				}
			}
		}
	}
}
